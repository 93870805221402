@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.TherapyMH {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0);
    position: relative;
  }
  
  .TherapyMH:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }


.TherapyMH .content {
    top: 10%;
    padding: 5rem 0 ;
    text-align: center;
}

.card-icon {
    width: 100%;
    margin: auto;
}

.card-icon img {
    width: 100%;
    border-radius: 10px;
}


/* HEADER */

.TherapyMH .TherapyMH-header {
    width: 100%;
    text-align: center;
    padding: 1rem;
}

.TherapyMH .left-content {
    top: 15%;
}

.TherapyMH .TherapyMH-header h1{
    color: #fd9b2f;
    font-size: 1rem;
    letter-spacing: 8px; 
    padding: .5rem;
}

.TherapyMH .TherapyMH-header p {
    color: rgb(26, 25, 25);
    font-size: 1.5rem;
    padding: .5rem;
}


/* CARD */


.mh-flex {
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.mh-flex .box-icon {
    font-size: 3rem;
    color: #fd9b2f;
    padding: 0;
}

.mh-flex h1 {
    color: black;
    font-size: 1rem;
    padding: 1rem 0;
}

.mh-flex p {
    color: #adadad;
}

.mh-flex .card {
    width: 35%;
    padding: 1rem;
}

.mh-btn {
    margin: auto;
    text-align: center;
}


 .mh-btn button {
    cursor: pointer;
    background-color: #f3c57d;
    font-size: 1rem;
    letter-spacing: 2px;
    font-weight: 600;
    color: var(--primary-color);
}

.mh-btn button:hover {
    cursor: pointer;
    background-color: rgb(25, 53, 104);
    color: white;
}


@media screen and (max-width:940px) {


    .mh-flex{
        flex-wrap: wrap;
    }

    .mh-flex .card {
        width: 40%;
        -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
        -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
        box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
        padding: 1rem;
    }

    .TherapyMH .TherapyMH-header p{
        font-size: 1rem;
    }


}


@media screen and (max-width:500px) {

.mh-flex .card {
    width: 100%;
}
}
