

  .hero {
    width: 100%;
    height: 90vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 3; /* Base layer for hero section */
  }
  
  .hero:before {
    content: '';
    position: absolute;
    background: linear-gradient(#031f3d67, #0655aa94), 
    url('../../assets/hero-bg.webp') no-repeat center bottom/cover;  height: 100%;
    width: 100%;
    z-index: 1;
  }
  
  .hero-container {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 2; /* Elevate hero container above the base */
    gap: 1rem;
    height: 100%;
  }
  
  
  
  /* Keyframes for sliding up */
  @keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(50px); /* Start position */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* End at its original position */
    }
  }
  
  .hero-caption
  {
    width: 100%;
    margin: auto;
    padding: 1rem;
  }
  
  .hero-form {
    width: 60%;
    margin: auto;
    padding: 1rem;
  }
  

  .hero h1 {
      font-size: 5rem;
      color: rgb(255, 255, 255);
      letter-spacing: 2px;
      font-family: 'Kuta', serif;
    }
  
  .hero p {
      font-size: 1rem;
      color: rgb(255, 255, 255);
      text-align: left;
      padding: 1rem 0;
    }
  
    .hero-btn {
      display: flex;
      gap: 2rem;
      justify-content: left;
    }
    
    .hero-btn .hero-btn1 {
      background-color: #58799d;
      color: white;
      border: none;
      padding: .8rem 3rem;
      border-radius: 1.5rem;
    }
    
    .hero-btn .hero-btn2 {
      background-color: #ffffff;
      color: #2E788C;
      border: none;
      padding: .8rem 3rem;
      border-radius: 1.5rem;
    }
    
    .hero-arrow {
      padding-top: 5px;
    }
    
  
  /* MEDIA QUERIES */

  @media screen and (max-width:940px) {
    
  .hero {
    height: auto;
  }

  .hero h1 {
    text-align: center;
    padding-top: 2rem;
  }

  .hero p {
    text-align: center;
}

    .hero-container {
      flex-wrap: wrap;
    }

    .hero-form {
      width: 70%;
      margin: auto;
      padding: 1rem;
    }
    .hero-btn {
      justify-content: center;
    }

  }
  
  @media screen and (max-width:600px) {

    .hero {
      height: 80vh;
    }

    .hero h1 {
      font-size: 2rem;
      line-height: normal;
    }

    .hero p {
        font-size: 14px;
    }

    .hero-arrow {
      display: none;
    }
    
    
    .hero-btn .hero-btn1 {
      background-color: #58799d;
      color: white;
      border: none;
      padding: .8rem 2rem;
      border-radius: 10px;
    }
    
    .hero-btn .hero-btn2 {
      background-color: #ffffff;
      color: #2E788C;
      border: none;
      padding: .8rem 2rem;
      border-radius: 10px;
    }
    
    /* .hero-form {
      width: 100%;
      text-align: center;
      margin: auto;
    } */

    .hero-form {
      display: none;
    }
  
  }
  