@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.TherapySA {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #00000049;
}
  
  .TherapySA:before {
    content: '';
    position: absolute;
    background: url('../../assets/hero-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.TherapySA .content {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 5rem 1rem;
}

.box-icon {
    font-size: 6rem;
    color: rgb(139, 85, 4);
}


/* LEFT */

.TherapySA .TherapySA-left {
    width: 40%;
    margin: auto;
}


.TherapySA .TherapySA-left h1{
    color: rgb(243, 237, 237);
    font-size: 2rem;
    padding: 2rem 0;
}

.TherapySA .TherapySA-left p {
    color: rgb(201, 197, 197);
    font-size: 14px;
    text-align: left;
}


/* RIGHT */

.box-icon {
    width: 100%;
}

 .TherapySA-right {
    width: 700px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}

.TherapySA-right .box {
    height: auto;
    width: 280px;
    background-color: rgba(235, 227, 227, 0.062);
    padding: .5rem ;
    border-radius: 10px;
}

.box-icon {
    width: 50px;
    margin: auto;
}

.box-icon img {
    width: 100%;
}

/* box 1 */

.box1 .box-content1 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;
    text-align: center;
    width: 100%;
    padding: 1rem;

}

.box1 .box-content1 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    text-align: center;
    font-size: 12px;
}

/* box 2 */

.box2 .box-content2 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;

}

.box2 .box-content2 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    font-size: 12px;
}

/* box 3 */

.box3 .box-content3 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;

}

.box3 .box-content3 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    font-size: 12px;

}

/* box 4 */

.box4 .box-content4 h1 {
    color: rgb(201, 197, 197);
    padding: 1rem 0;
    font-size: 1.2rem;
    text-align: center;
}

.box4 .box-content4 p {
    color: rgb(238, 230, 230);
    text-align: center;
    padding: 0 1rem;
    font-size: 12px;
}


.sa-btn {
    margin: auto;
    text-align: center;
}


 .sa-btn button {
    cursor: pointer;
    background-color: #f3c57d;
    font-size: 1rem;
    letter-spacing: 2px;
    font-weight: 600;
    color: var(--primary-color);
}

.sa-btn button:hover {
    cursor: pointer;
    background-color: rgb(25, 53, 104);
    color: white;
}



.hide-btn {
    display: none;
}

@media screen and (max-width:940px) {
    .TherapySA .TherapySA-left {
        width: 100%;
    }

    
    .TherapySA .TherapySA-right {
        width: 100%;
    }


    .hide {
        display: none;
    }

    .hide-btn {
        display: block;
        padding: 2rem 0;
    }


    .TherapySA .content {
        flex-wrap: wrap;
        text-align: center;
    }

    .TherapySA .content p {
        text-align: center;
    }

    .TherapySA-right  .box{
        width: 90vw;
    }
    
    .TherapySA .TherapySA-left h1 {
        font-size: 2rem;
    }



    
}

