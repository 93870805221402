@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.schizoaffective-section {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
}

.schizoaffective-section:before {
  content: '';
  position: absolute;
  background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.03;
}

.schizoaffective-section-content {
  padding: 3rem 1rem;
}

.schizoaffective-section-header {
  padding: 1rem;
}

.schizoaffective-section-header h5 {
  font-size: .9rem;
  color: orange;
}


.schizoaffective-section-flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.schizoaffective-section-left {
  width: 50%;
  margin: auto;
}

.schizoaffective-section-right {
  width: 500px;
  margin: auto;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
}

.schizoaffective-section-right img {
  width: 100%;
  border-radius: 15px;
  padding: .5rem;
}

.schizoaffective-section h1 {
  font-size: 1.2rem;
  padding: .5rem 0;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

  .schizoaffective-section-flex {
    flex-wrap: wrap-reverse;
  }
  
  .schizoaffective-section-left {
    width: 100%;
    margin: auto;
  }
  
  .schizoaffective-section-right {
    width: 100%;
    margin: auto;
  }

  .schizoaffective-section-left h1 {
    text-align: center;
  }

  .schizoaffective-section-header h1 {
    text-align: center;
  }
  
  }



