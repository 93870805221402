@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.menu2 {
    padding: 5rem 0;
}

.menu2-header {
    padding: 1rem;
    text-align: center;
}

.menu2-header h1 {
  padding: 1rem;
}

.menu2-wrap
{
  width:100%;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.menu2-card {
    width: 280px;
    background-color: #759eb8;
}

.menu2-card img {
    width: 100%;
}


.menu2-card-caption {
  padding: 1rem;
  text-align: center;
}


.menu2-card-caption p {
  font-size: 14px;
  text-align: center;
  color: white;
}

.menu2-card-caption h1 {
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
}


@media (max-width: 940px) {
  .mh-card-wrap {
    width: 100%;
  }

}


@media (max-width: 700px) {
.menu2-card {
    width: 90%;
    background-color: #759eb8;
}

.menu2-header h1 {
    font-size: 1.2rem;
}

}
