@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');

.section-bg {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
}

.section-bg:before {
  content: '';
  position: absolute;
  background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.05;
}

#cards {
  padding: 3rem 0;
}

.main-cards-header {
  padding: 1rem;
}

.main-cards-header p {
  font-size: 14px;
  padding: 1rem;
  color: rgb(8, 8, 8);
  text-align: center;
}

.main-cards-header h1 {
  text-align: center;
  font-family: 'Instrument Serif', serif;
  font-size: 1.5rem;
  color: rgb(8, 8, 8);
  letter-spacing: 5px;
}

/* CARDS */

.cards-flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.services-card {
  width: 300px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.100);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.100);
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.100);
}

.services-card img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.services-card h1 {
  font-size: 1rem;
  color: rgb(61, 58, 58);
  text-align: center;
  padding: 1rem;
}

.services-card p {
  font-size: 14px;
  color: rgb(61, 58, 58);
  text-align: center;
  padding: .5rem;
}

.services-card-btn {
  text-align: center;
}

.services-card-btn button {
  border: 1px solid rgba(22, 21, 21, 0.199);
}

.services-card-btn button:hover {
  background-color: rgb(230, 206, 161);
  border: none;
  color: white;
}



@media screen and (max-width:940px) {
  .cards-flex {
    flex-wrap: wrap;
  }
  
  .services-card {
    width: 40%;
  }
}


@media screen and (max-width:700px) {
  .cards-flex {
    flex-wrap: wrap;
  }
  
  .services-card {
    width: 80%;
  }
}







