@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.landingPageForm {
  width: 100%;
  height: auto;
  background-color: #58799d80;
  padding: 1rem;
  border-radius: 5px;
}

.input-box {
  height: 50px;
  width: 100%;
  margin: 5px 0;
}

.message-box {
  min-height: 110px;
}

.input-box .message-box textarea{
  padding-top: 6px;
}

.right-side .button{
  display: inline-block;
  margin-top: 12px;
}

.contact-btn {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: var(--primary-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-btn:hover {
  background: rgb(21, 155, 148);
}





