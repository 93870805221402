@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.mental-health {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #0a0a0a71;
  }
  
  .mental-health:before {
    content: '';
    position: absolute;
    background: url('../../assets/mental-health-hero.webp') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .mental-health-content {
    display: block;
    position: absolute;
    top: 60%;
    padding: 1rem;
}

.mental-health h1 {
    color: white;
    font-size: 4rem;
}

.mental-health p {
    color: white;
    font-size: 16px;
    text-align: center;
}



/* MEDIA QUERIES - HERO */

@media screen and (max-width:940px) {

.mental-health h1 {
    font-size: 2.5rem;
    text-align: center;
}


}

