@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.treatment-overview {
    padding: 3rem 1rem;
}

.treatment-overview p {
    padding: .5rem;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: rgb(65, 66, 66);
}

.treatment-overview h1 {
    padding: 1rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    color: rgb(65, 66, 66);
}



