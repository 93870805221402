.footer {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #00000049;
}
  
  .footer:before {
    content: '';
    position: absolute;
    background: url('../../assets/hero-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.logo-footer {
    width: 250px;
}

.logo-footer img {
    width: 100%;
    height: auto;
}

.footer .top .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: white;
    cursor: pointer;
}

.footer h2 {
    font-size: 2rem;
}

.col {
    width: 300px;
}

.footer .col-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem;
    width: 100%;
}

.footer h3 {
    margin: 1rem 0 2rem 0;
    text-transform: uppercase;
    text-align: center;
    color: white;
    font-size: 1rem;
    font-weight: normal;
}


.footer p {
    margin: .5rem 0;
    color: rgb(9, 147, 189);
    text-align: center;

}

.copyright p {
    color: rgb(235, 240, 241);
    text-transform: uppercase;
    text-align: center;
}

.certification {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.certificationLeft {
    width: 100%;
    text-align: center;
    margin: auto;

}



.certification h3 {
    text-align: center;
    font-size: .8rem;
    font-weight: normal;
}

.certification p {
    text-align: center;
    color: white;
}

.certificationLeftImage {
    width: 200px;
    margin: auto;
}

.certificationLeftImage img{
    width: 100%;
}

.certification a:hover {
    text-align: center;
    color: rgb(10, 208, 235);
}


@media screen and (max-width: 940px) {



}


