@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.jobs {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
  }
  
  .jobs:before {
    content: '';
    position: absolute;
    background: url('../../assets/submenu-bg-jobs.webp') no-repeat center top/cover;
    height: 80vh;
    width: 100%;
    z-index: -1;
  }


.jobs .jobs-content {
    display: block;
    position: absolute;
    top: 40%;
    padding: 1rem;
}

.jobs h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: rgba(255, 255, 255, 0.616);


}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.jobs h2 {
    font-size: 1.5rem;
}

.jobs h1 {
    font-size: 2rem;
}
}