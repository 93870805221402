@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.about-container {
    padding: 5rem 0;
}

.about-content {
    display: flex;
    justify-content: center;
}

.about-content-left {
    width: 400px;
    text-align: center;
    margin: auto;
    padding: 1rem;
}

.about-content-left img {
    width: 100%;
    border-radius: 1rem;
}

.about-content-right {
    width: 55%;
    margin: auto;
    padding: 1rem;
}

.about-content-right h1 {
    text-transform: uppercase;
    color: #fd9b2f;
    font-size: 1rem;
    letter-spacing: 8px; 
    padding: 1rem 0;
}

.about-content-right p {
    font-size: 14px;
    text-align: justify;
}



@media screen and (max-width:940px) {


    .about-content {
        flex-wrap: wrap;
    }
    

    .about-content-left {
        width: 90%;
    }
    .about-content-right {
        width: 90%;
    }
}
