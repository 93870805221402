@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
    width: 100%;
    height: auto;
    z-index: 10;
    background: #ffffff;
    position: relative;
    text-align: center;
}

.nav-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}


.navbar-logo {
  z-index: 2;
  width: 200px;
  margin: auto 2rem;
}

.navbar-logo img {
  width: 100%;
  height: auto;
  display: block;
}

.navbar .icon {
  font-size: 2rem;
  color: #58799d;
}

 .navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
} 

.hamburger {
    display: none;
    padding: 1rem;
    margin: auto 0;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    padding: .8rem 0;
}

  .navbar ul {
    margin: auto;
  }

.navbar li {
    width: max-content;
    padding: .3rem 1rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}


.navbar li a {
  color: rgb(46, 51, 49);
  font-family: 'roboto', sans-serif;
  text-transform: uppercase;
}

.navbar li a:hover {
  color: rgb(211, 124, 11);
}

  .menu-item .sub__menus li a {
    font-size: 13px;
    padding: -1rem ;
  }

  .menu-item .sub__menus li a:hover {
    color: rgb(255, 123, 0) !important;
  }

  li .submenu-header {
    font-size: 14px;
    color: rgb(36, 131, 27);
    font-weight: bolder;
    text-decoration: underline;
    cursor: pointer;
  }


/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #ffffff;
    border: none;
    border-radius: 5px;
    width: auto;
    margin-top: 6px;
    z-index: 1000;
  }


  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows {
    position: relative;
  }

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 7px;
  }


    .logo-hide {
      display: none;
    }




@media screen and (max-width: 1200px) {

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    display: none;
  }

  .navbar .hamburger {
    display: block;
    z-index: 15;
}


    .navbar ul {
      margin: auto 0;
    }

    .logo-hide {
      display: block;
      padding: .8rem 0;
    }

    .mid-logo {
      display: none;
    }

    .navbar li a {
      color: rgb(26, 24, 24);
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 75%;
        height: 100vh;
        top: 0;
        left:-100%;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        transition: 0.5s ease-in;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 14px;
        color: white;
      }


}



@media screen and (max-width: 940px) { 
  .sub__menus {
    position: absolute;
    background-color: #58799d;
    left: 0;
    width: 100%;
    border-radius: 0;
  }

  .menu-item .sub__menu {
    width: 100%;
    left: 0;
  }

  
  .navbar li {
    width: 100%;
    border-bottom: 1px dashed #58799d5b;
}



}


