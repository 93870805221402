@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.section3 {
    width: 100%;
    height: auto;
    background: rgba(43, 55, 88, 0);
    position: relative;
  }
  
  .section3:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }

.section3-content {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 4rem 1rem;
}

.section3-left {
    width: 500px;
    text-align: center;
    margin: auto;
    -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
    -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
    box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
}

.section3-left img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.section3-right {
    width: 600px;
    height: auto;
    margin: auto;
}


.section3-right h2 {
    font-size: 1rem;
    color: black;
    padding: 1rem 0;
}

.section3-right h1 {
    text-transform: uppercase;
    color: var(--primary-dark);
    font-size: 1rem;
    letter-spacing: 8px; 
}

.shape3 {
    width: 200px;
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 1;
    opacity: 0.8;
}

.shape3 img {
    width: 100%;
    height: auto;
}


@media screen and (max-width:940px) {

    .section3-content {
        flex-wrap: wrap;
    }

    .section3-left {
        width: 90%;
    }

    .section3-right {
        width: 90%;
    }

    .section3-right h1 {
        text-align: center; 
    }

    .section3-right h2 {
        text-align: center; 
    }

    .section3-right p {
        text-align: justify;
    }

    .shape3 {
        display: none;
    }

}
