@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.section2 {
    padding: 2rem 0;
    background: rgb(43, 55, 88);
    margin: 3rem 0 0 0;
}

.section2-content {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.section2 .card {
    width: 30%;
    padding: 1rem;
}

.card .icon {
    text-align: center;
}

.section2 .card span {
    color: #b89646;
    font-size: 3rem;
    text-align: center;
    
}

.section2-content h1 {
    text-align: center;
    color: #f7f2f2;
    padding: 1rem;
    font-size: 1.2rem;
    font-family: 'Instrument Serif', serif;
    letter-spacing: 1px;
}

.section2-content p {
    text-align: center;
    color: #f7f2f2;
}


@media screen and (max-width:940px) {

    .section2-content {
        flex-wrap: wrap;
    }

    .section2 .card {
        width: 90%;
    }
}
