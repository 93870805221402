


.section1 {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0);
    position: relative;
  }
  
  .section1:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.03;
  }
  
  .section1-header {
    text-align: center;
    padding: 1rem;
    margin-top: 2rem;
  }

  .section1-header h1 {
    color: #ebae39;
    font-size: 3rem;
    letter-spacing: 2px;
    font-family: 'Kuta', serif;
    
}

.section1-header p {
    text-align: center;
    font-family: 'roboto', serif;
    font-size: 16px;
}

.section1-content {
    width: 100%;
    margin: auto;
    padding: 0 2rem;
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.section1-left {
    width: 50%;
    margin: auto;
    padding: 1rem;
}

.section1-right {
    width: 600px;
    margin: auto;
    -webkit-box-shadow: 0px 0px 10px -5px rgb(17, 17, 16);
    -moz-box-shadow: 0px 0px 10px -5px rgb(17, 17, 16);
    box-shadow: 0px 0px 10px -5px rgb(17, 17, 16);
    padding: 1rem;
}

.section1-right img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.section1 h2 {
    font-size: 14px;
    color: #ebae39;
    font-size: 1.2rem;
    letter-spacing: 2px;
    font-family: 'Kuta', serif;
    padding: .5rem 0;
    font-weight: normal;
}

.section1-left p {
    color: #474444;
    text-align: justify;
    font-size: 16px;
    font-family: 'roboto', 'serif';
}



/* Flex 2 */

.section1-flex2 {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.flex2-left {
    width: 600px;
    margin: auto;
    -webkit-box-shadow: 0px 0px 10px -5px rgb(17, 17, 16);
    -moz-box-shadow: 0px 0px 10px -5px rgb(17, 17, 16);
    box-shadow: 0px 0px 10px -5px rgb(17, 17, 16);
    padding: 1rem;
}

.flex2-left img {
    width: 100%;
    border-radius: 5px;
}

.flex2-right {
    width: 50%;
    margin: auto;
    padding: 1rem;
 }


 .flex2-right p {
    text-align: justify;
 }






@media  screen and (max-width: 940px) {


    .section1-left h1 {
        text-align: center;
    } 

    .section1-content {
        flex-wrap: wrap;
        padding: 0;
    }

    .section1-left {
        width: 90%;
    }

    .section1-right {
        width: 90%;
    }

    .section1-flex2 {
        flex-wrap: wrap-reverse;
        padding: 0;
    }

    .flex2-left {
        width: 90%;
    }

    .flex2-right {
        width: 90%;
    }

}